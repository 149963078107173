import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './reducers/authReducer'; // Import your authReducer

// Root reducer combining all reducers
const rootReducer = combineReducers({
  auth: authReducer,
  // ...other reducers
});

// Persist config
const persistConfig = {
  key: 'root',
  storage,
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];

// Configure the store using configureStore function
const store = configureStore({
  reducer: persistedReducer,
  middleware,
  devTools: window._Redux_devtools_extension ? true : false, // Enable Redux DevTools if the global variable is available
});

// Persist the store
const persistor = persistStore(store);

export { store, persistor };
