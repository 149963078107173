import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import { Link } from "react-router-dom";
import lightLogo from "../../assets/images/logo.png";

const Footer = () => {
    const footer = [
        {
            id: 1,
            title: "Company",
            menu: [
                {
                    id: 1,
                    link: "/aboutus",
                    subTitle: "About Us"
                },
                {
                    id: 2,
                    link: "/contact",
                    subTitle: "Contact Us"
                },
                {
                    id: 3,
                    link: "/services",
                    subTitle: "Services"
                },
                {
                    id: 4,
                    link: "/blog",
                    subTitle: "Blog"
                },
                {
                    id: 5,
                    link: "/team",
                    subTitle: "Team"
                },
                {
                    id: 6,
                    link: "/pricing",
                    subTitle: "Pricing"
                },
            ]
        },
        {
            


            id: 2,
            title: "Services",
            menu: [
                {
                    id: 1,
                    link: "/accounting-and-assurance",
                    subTitle: "Accounting and Assurance"
                },
                {
                    id: 2,
                    link: "/business-process-decision-making",
                    subTitle: "Business Process Decision Making"
                },
                {
                    id: 3,
                    link: "/business-plans",
                    subTitle: "Business Plans"
                },
                {
                    id: 4,
                    link: "/taxation",
                    subTitle: "Taxation"
                },
            ]
        },
        {
            id: 3,
            title: "Quick Links",
            menu: [
                {
                    id: 1,
                    link: "/",
                    subTitle: "Hot Jobs"
                },
            ]
        },
        {
            id: 4,
            title: "Support",
            menu: [
                {
                    id: 1,
                    link: "/contact",
                    subTitle: "Help Center"
                },
                {
                    id: 2,
                    link: "/faqs",
                    subTitle: "FAQ'S"
                },
                {
                    id: 3,
                    link: "/",
                    subTitle: "Privacy Policy"
                }
            ]
        },
    ];
    const footerIcons = [
        {
            id: 1,
            socialIcon: "uil uil-facebook-f",
        },
        {
            id: 2,
            socialIcon: "uil uil-linkedin-alt",
        },
        {
            id: 3,
            socialIcon: "uil uil-google",
        },
        {
            id: 4,
            socialIcon: "uil uil-twitter",
        }
    ];
    return (
        <React.Fragment>
            <section className="bg-footer">
                <Container>
                    <Row>
                        <Col lg={4}>
                            <div className="footer-item mt-4 mt-lg-0 me-lg-5">
                            <img src={lightLogo} alt="" className="logo-light" style={{filter: "brightness(0.2) invert(1)"}}/>
                                <p className="text-white-50">Stellar Conseil is a team of Professionals like Chartered Accountant, Company Secretary, HR Managers, IT Professionals, Architects, Lawyers, etc. We draw our strength from our team of professionals who have significant functional, industry expertise across service lines and are well-equipped to deliver outstanding results.</p>
                                <p className="text-white mt-3">Follow Us on:</p>
                                <ul className="footer-social-menu list-inline mb-0" >
                                    {(footerIcons).map((footerIcondetails, key) => (
                                        <li className="list-inline-item" key={key}>
                                            <Link to="#"><i className={footerIcondetails.socialIcon}></i></Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                        {(footer).map((footerdetails, key) => (
                            <Col lg={2} xs={6} key={key}>
                                <div className="footer-item mt-4 mt-lg-0">
                                    <p className="fs-16 text-white mb-4">{footerdetails.title}</p>
                                    {(footerdetails.menu || []).map((menuInner, key) => (
                                        <ul className="list-unstyled footer-list mb-0" key={key}>
                                            <li><Link to={menuInner.link}><i className="mdi mdi-chevron-right"></i> {menuInner.subTitle}</Link></li>
                                        </ul>
                                    ))}
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
            <div className="footer-alt">
                <Container >
                    <Row >
                        <Col lg={12}>
                            <p className="text-white-50 text-center mb-0">
                                {new Date().getFullYear()} &copy; Stellarconseil
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )
}

export default Footer;
