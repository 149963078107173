// src/redux/reducers/authReducer.js

const initialState = {
  userData: null,
  // ...other initial state properties
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        userData: action.payload.userData,
        // ...update other relevant properties
      };
    case 'LOGOUT':
      return {
        ...state,
        userData: null, // Clear user data on logout
        // ...update other relevant properties
      };
    // ...other cases
    default:
      return state;
  }
};

export default authReducer; // Export the reducer as the default export
